* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #000000;
}

.black-theme * {
  background-color: #000000;
  color: white
}
.black-themetwo * {
  background-color: #151619;
  color: white
}

.selectedbutton{
  background-color: white !important;
  color: black !important;
}

.modal{
  backdrop-filter: blur(10px);
}

.btn-primary {
  background-color: #6565ff;
  border-color: #6565ff;
}

.btn-primary:hover {
  background-color: #6565ff;
  border-color: #6565ff;
}

#achievements {
  scroll-margin-top: 100px;

}
#brand-development {
  scroll-margin-top: 100px;

}

#services {
  scroll-margin-top: 100px;
}

#portfolio {
  scroll-margin-top: 130px;
}


@font-face {
  font-family: Barranco;
  src: url('./components/BarrancoInline-Black.ttf');
}



.site-footer {
  background-color: #000000;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
}

.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5
}

.site-footer hr.small {
  margin: 20px 0
}

.site-footer h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px
}

.site-footer a {
  color: #737373;
}

.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links {
  padding-left: 0;
  list-style: none
}

.footer-links li {
  display: block
}

.footer-links a {
  color: #737373
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links.inline li {
  display: inline-block
}

.site-footer .social-icons {
  text-align: right
}

.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d
}

.copyright-text {
  margin: 0
}

@media (max-width:991px) {
  .site-footer [class^=col-] {
    margin-bottom: 30px
  }
}

@media (max-width:767px) {
  .site-footer {
    padding-bottom: 0
  }

  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center
  }
}

.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.social-icons li {
  display: inline-block;
  margin-bottom: 4px
}

.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px
}

.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe
}

.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px
}

.social-icons a.facebook:hover {
  background-color: #3b5998
}

.social-icons a.twitter:hover {
  background-color: #00aced
}

.social-icons a.linkedin:hover {
  background-color: #007bb6
}

.social-icons a.dribbble:hover {
  background-color: #ea4c89
}

@media (max-width:767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600
  }
}


.form-control {
  background-color: rgba(0, 0, 0, 0);
  border-color: #6565ff;
  color: white;
}

.form-control:focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: #6565ff;
  color: white;
}

.form-control::placeholder {
  color: lightgrey;
}

.btn-primary:disabled {
  background-color: #6565ff;
  border-color: #6565ff;


}

input:-internal-autofill-selected {

  /* -webkit-box-shadow: 0 0 0px 1000px #6565ff inset !important; */
  box-shadow: 0 0 0px 1000px #6565ff inset !important;
  /* outline: 5px solid rgb(0 0 0 / .5); */
  color: white !important;
}

.form-control:disabled {
  background-color: rgba(101, 101, 255, 0.1);
  cursor: not-allowed;

}

@keyframes mymove {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}


.brand-image{
  -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: mymove;
    animation-name: mymove;
}
hr{
  opacity: 0.75;
  color:white
}


.shade {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0, 0, 0.4);
}
